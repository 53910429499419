import i18n from "@/i18n";

const routes = {
  path: "/golfcourse",
  name: "golfcourse",
  component: () =>
    import(
      /* webpackChunkName: "golfcourse" */ "@/views/golfcourse/GolfcourseMain"
    ),
  children: [
    {
      path: "courselist",
      alias: "",
      name: "CourseList",
      component: () =>
        import(
          /* webpackChunkName: "golfcourse" */ "@/views/golfcourse/CourseList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.golfcourse.golfcourse"),
        m_subtitle: i18n.t("menu.golfcourse.golfcourse"),
        menu_num: 4,
        sub_menu_num: 41,
        step: [
          i18n.t("menu.golfcourse.golfcourse"),
          i18n.t("menu.golfcourse.golfcourse"),
        ],
      },
    },
    {
      path: "courseView/:id",
      name: "CourseView",
      component: () =>
        import(
          /* webpackChunkName: "golfcourse" */ "@/views/golfcourse/CourseView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.golfcourse.golfcourse_information"),
        m_subtitle: i18n.t("menu.golfcourse.golfcourse_information"),
        menu_num: 4,
        sub_menu_num: 41,
        step: [
          i18n.t("menu.golfcourse.golfcourse"),
          i18n.t("menu.golfcourse.golfcourse_information"),
        ],
      },
    },
  ],
};

export default routes;
