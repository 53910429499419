<template>
  <nav id="ddmenu" :class="{ scroll: last_scroll_position > 0 }">
    <header class="wrap">
      <h1 class="logo" @click="$router.push('/').catch(() => {})">
        <img :src="logo" />
      </h1>
      <ul :class="{ open: mMenu }">
        <li class="mobile-nav-header">
          <div>
            <strong>Nickname<!-- Login --></strong>
            <div class="ms-auto">
              <router-link to="/login" title="Log in" v-if="!login">
                <i class="material-icons">power_settings_new</i>
              </router-link>
              <router-link to="/logout" title="Log out" v-else>
                <i class="material-icons-outlined">logout</i>
              </router-link>
              <router-link to="/login" title="MyZone" v-if="!login">
                <i class="material-icons-outlined mx-2">person</i>
              </router-link>
              <router-link to="/membership/mysummary" title="MyZone" v-else>
                <i class="material-icons-outlined mx-2">person</i>
              </router-link>
              <span
                @click="mmenu_on_off"
                :aria-label="$t('button.common.close')"
                title="Close"
              >
                <i class="material-icons-outlined">close</i>
              </span>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 1 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(1)"
        >
          <span class="nav-item" @mouseover="menu_on(1)">
            <b :class="{ active: menu_num === 1 }">
              {{ $t("menu.membership.membership") }}
            </b>
          </span>
          <div class="dropdown" v-if="menu === 1">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/membership/mysummary"
                    :class="{ active: sub_menu_num === 11 }"
                  >
                    {{ $t("menu.membership.my_summary") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/myrecords/myrounds"
                    :class="{ active: sub_menu_num === 12 }"
                  >
                    {{ $t("menu.membership.my_record") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/mybrandroom/fittinglist"
                    :class="{ active: sub_menu_num === 14 }"
                  >
                    {{ $t("menu.membership.my_brandroom") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/membership/myaccount/myprofile"
                    :class="{ active: sub_menu_num === 13 }"
                  >
                    {{ $t("menu.membership.my_account") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 2 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(2)"
        >
          <span class="nav-item" @mouseover="menu_on(2)">
            <b :class="{ active: menu_num === 2 }">
              {{ $t("menu.tournament.tournament") }}
            </b>
          </span>
          <div class="dropdown" v-if="menu === 2">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/tournament/tournamentschedule"
                    :class="{ active: sub_menu_num === 21 }"
                  >
                    {{ $t("menu.tournament.admin_tournament") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/tournament/shoptournamentschedule"
                    :class="{ active: sub_menu_num === 22 }"
                  >
                    {{ $t("menu.tournament.shop_tournament") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/tournament/jointournament"
                    :class="{ active: sub_menu_num === 23 }"
                  >
                    {{ $t("menu.tournament.tournament_information") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 3 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(3)"
        >
          <span class="nav-item" @mouseover="menu_on(3)">
            <b :class="{ active: menu_num === 3 }">{{
              $t("menu.event.event")
            }}</b>
          </span>
          <div class="dropdown" v-if="menu === 3">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/event/netizenlesson/swinglist"
                    :class="{ active: sub_menu_num === 31 }"
                  >
                    {{ $t("menu.event.netizen_lesson") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/minigame"
                    :class="{ active: sub_menu_num === 32 }"
                  >
                    {{ $t("menu.event.minigame") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/winaminigame"
                    :class="{ active: sub_menu_num === 33 }"
                  >
                    {{ $t("menu.event.minigame_winners") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/event/joinminigame"
                    :class="{ active: sub_menu_num === 34 }"
                  >
                    {{ $t("menu.event.minigame_informaition") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 4 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(4)"
        >
          <span class="nav-item" @mouseover="menu_on(4)">
            <b :class="{ active: menu_num === 4 }">
              {{ $t("menu.golfcourse.golfcourse") }}
            </b>
          </span>
          <div class="dropdown" v-if="menu === 4">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/golfcourse/courselist"
                    :class="{ active: sub_menu_num === 41 }"
                  >
                    {{ $t("menu.golfcourse.golfcourse") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 5 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(5)"
        >
          <span class="nav-item" @mouseover="menu_on(5)">
            <b :class="{ active: menu_num === 5 }">
              {{ $t("menu.find_location.find_location") }}
            </b>
          </span>
          <div class="dropdown" v-if="menu === 5">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/findlocation/stores"
                    :class="{ active: sub_menu_num === 51 }"
                  >
                    {{ $t("menu.find_location.find_location") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          :class="{ over: menu === 6 }"
          @mouseleave="menu_off()"
          @click="menu_on_m(6)"
        >
          <span class="nav-item" @mouseover="menu_on(6)">
            <b :class="{ active: menu_num === 6 }">{{
              $t("menu.support.support")
            }}</b>
          </span>
          <div class="dropdown" v-if="menu === 6">
            <div class="dd-inner">
              <ul class="column">
                <li>
                  <router-link
                    to="/support/notice"
                    :class="{ active: sub_menu_num === 61 }"
                    >{{ $t("menu.support.notice") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/support/inquiry"
                    :class="{ active: sub_menu_num === 62 }"
                  >
                    {{ $t("menu.support.inquiry") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/support/resources"
                    :class="{ active: sub_menu_num === 63 }"
                  >
                    {{ $t("menu.support.resources") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>

      <div
        class="header-utility justify-content-lg-between justify-content-xl-end"
        v-if="!login"
      >
        <router-link
          to="/login"
          title="Log in"
          class="d-none d-lg-inline-block"
        >
          <i class="material-icons">power_settings_new</i>
          <span>{{ $t("menu.login.login") }}</span>
        </router-link>
        <router-link
          to="/login"
          class="d-none d-lg-inline-block ms-0 ms-xl-2"
          title="MyZone"
        >
          <i class="material-icons-outlined">person</i>
          <span>{{ $t("menu.login.my_zone") }}</span>
        </router-link>
        <div
          class="d-xl-none"
          :class="[{ 'menu-icon-active': mMenu }]"
          @click="mmenu_on_off()"
          title="Navigation menu"
        >
          <span class="hamburger-icon" aria-label="Navigation menu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
      <div
        class="header-utility justify-content-lg-between justify-content-xl-end"
        v-else
      >
        <router-link
          to="/logout"
          title="Log out"
          class="d-none d-lg-inline-block"
        >
          <i class="material-icons-outlined">logout</i>
          <span>{{ $t("menu.login.logout") }}</span>
        </router-link>
        <router-link
          to="/membership/mysummary"
          class="d-none d-lg-inline-block ms-0 ms-xl-2"
          title="MyZone"
        >
          <i class="material-icons-outlined">person</i>
          <span>{{ $t("menu.login.my_zone") }}</span>
        </router-link>
        <div
          class="d-xl-none"
          :class="[{ 'menu-icon-active': mMenu }]"
          @click="mmenu_on_off()"
          title="Navigation menu"
        >
          <span class="hamburger-icon" aria-label="Navigation menu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </header>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menu: 0,
      mMenu: false,
      is_mobile: false,
      login: sessionStorage.getItem("token") ? true : false,
      last_scroll_position: 0,
    };
  },
  methods: {
    menu_on: function (eq) {
      if (this.is_mobile) return;
      this.menu = eq;
    },
    menu_on_m: function (eq) {
      if (!this.is_mobile) return;
      if (this.menu === eq) this.menu = 0;
      else this.menu = eq;
    },
    menu_off: function () {
      if (this.is_mobile) return;
      this.menu = 0;
    },
    mmenu_on_off: function () {
      if (this.mMenu === false) {
        this.menu = this.menu_num;
        this.mMenu = true;
      } else this.mMenu = false;
    },
    check_mobile: function () {
      let filter = "win16|win32|win64|mac|macintel";
      if (navigator.platform) {
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
          this.is_mobile = true;
        } else {
          this.is_mobile = false;
        }
      }
    },
    onScroll: function () {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
    },
    check_store: function () {
      if (sessionStorage.getItem("token")) {
        this.$store.dispatch("check_login");
      }
    },
    widthCheck() {
      // if (window.innerWidth < 1000) {
      //   this.icon_visible = false;
      // } else {
      //   this.icon_visible = true;
      // }
    },
    handleResize(event) {
      // if (window.innerWidth < 1000) {
      //   this.icon_visible = false;
      // } else {
      //   this.icon_visible = true;
      // }
    },
    resizeEvent() {
      // window.addEventListener("resize", this.handleResize);
    },
  },
  mounted() {
    if (this.$store.state.status === "success") {
      this.login = true;
    }
    window.addEventListener("scroll", this.onScroll);
    this.resizeEvent();
  },
  beforeDestroy() {
    this.resizeEvent();
  },
  created() {
    this.check_mobile();
    this.check_store();
    this.widthCheck();
  },
  watch: {
    "$store.state.status": function () {
      this.login = this.$store.state.status === "success" ? true : false;
    },

    "$route.name": function () {
      this.mMenu = false;
    },
    "$route.meta.menu_num": function () {
      this.memu = this.$route.meta.menu_num;
    },
  },
  computed: {
    logo: function () {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Find_Id_Pwd" ||
        this.$route.name === "Terms" ||
        this.$route.name === "Privacy" ||
        this.$route.name === "ConfirmPassword" ||
        this.last_scroll_position > 0
      )
        return "/img/logo_black.png";
      else return "/img/logo_white.png";
    },
    menu_num: function () {
      return this.$route.meta.menu_num || "";
    },
    sub_menu_num: function () {
      return this.$route.meta.sub_menu_num || "";
    },
  },
};
</script>
