<template>
  <div id="term-dfn">
    <dfn @click="getTermInfo(getname)" class="ms-auto">
      <span class="material-icons-outlined"> help_outline </span
      >{{ $t("text.common.term_dfn") }}
    </dfn>

    <b-modal
      id="modal-term-dfn"
      ref="modal-term-dfn"
      size="xl"
      centered
      scrollable
      body-class="pt-0"
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("text.common.term_dfn") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-term-dfn')"
        >
        </b-button>
      </template>

      <div class="tab-sub position-sticky">
        <swiper
          :options="{
            slidesPerView: 'auto',
            freeMode: false,
            loop: false,
            observer: true,
            observeParents: true,
          }"
        >
          <swiper-slide :class="{ active: TabName == 'score' }">
            <div @click="TabName = 'score'">
              {{ $t("text.common.score") }}
            </div>
          </swiper-slide>
          <swiper-slide :class="{ active: TabName == 'event' }">
            <div @click="TabName = 'event'">
              {{ $t("text.common.event") }}
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="tab-content" id="tabContent">
        <!-- TabName == score -->
        <div class="tab-pane fade show" :class="{ active: TabName == 'score' }">
          <div class="accordion list-type" role="tablist">
            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn1 class="collapse-content">
                  {{ $t("title.common.avg_score") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn1"
                accordion="tab-score-accordion"
                role="tabpanel"
                visible
              >
                <p>{{ $t("text.dfn.avg_score") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn2 class="collapse-content">
                  {{ $t("title.common.max_distance") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn2"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.max_distance") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn3 class="collapse-content">
                  {{ $t("title.common.avg_putts_distance") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn3"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.avg_putts_distance") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn4 class="collapse-content">
                  {{ $t("title.common.score") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn4"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.score") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn5 class="collapse-content">
                  {{ $t("title.common.shot") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn5"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.shot") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn6 class="collapse-content">
                  {{ $t("title.common.avg_distance") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn6"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.avg_distance") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn7 class="collapse-content">
                  {{ $t("title.common.fareway_hit") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn7"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.fareway_hit") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn8 class="collapse-content">
                  {{ $t("title.common.par_save_rate") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn8"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.par_save_rate") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn9 class="collapse-content">
                  {{ $t("title.common.gir") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn9"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.gir") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn10 class="collapse-content">
                  {{ $t("title.common.par") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn10"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.par") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn11 class="collapse-content">
                  {{ $t("title.common.handicap") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn11"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.handicap") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-score-dfn12 class="collapse-content">
                  {{ $t("title.common.mulligan") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-score-dfn12"
                accordion="tab-score-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.mulligan") }}</p>
              </b-collapse>
            </b-card>
          </div>
          <!-- // accordion -->
        </div>
        <!-- // TabName == score -->

        <!-- TabName == event -->
        <div class="tab-pane fade show" :class="{ active: TabName == 'event' }">
          <div class="accordion list-type" role="tablist">
            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn1 class="collapse-content">
                  {{ $t("text.common.holeinone") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn1"
                accordion="tab-event-accordion"
                role="tabpanel"
                visible
              >
                <p>{{ $t("text.dfn.holeinone") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn2 class="collapse-content">
                  {{ $t("text.common.albatross") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn2"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.albatross") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn3 class="collapse-content">
                  {{ $t("text.common.eagle") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn3"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.eagle") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn4 class="collapse-content">
                  {{ $t("text.common.birdie") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn4"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.birdie") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn5 class="collapse-content">
                  {{ $t("text.common.eagle_p5") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn5"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.eagle_p5") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn6 class="collapse-content">
                  {{ $t("text.common.birdie_p5") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn6"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.birdie_p5") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn7 class="collapse-content">
                  {{ $t("text.common.par_p5") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn7"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.par_p5") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn8 class="collapse-content">
                  {{ $t("text.common.eagle_p4") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn8"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.eagle_p4") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn9 class="collapse-content">
                  {{ $t("text.common.birdie_p4") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn9"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.birdie_p4") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn10 class="collapse-content">
                  {{ $t("text.common.par_p4") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn10"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.par_p4") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn11 class="collapse-content">
                  {{ $t("text.common.longest") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn11"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.longest") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn12 class="collapse-content">
                  {{ $t("text.common.nearpin") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn12"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.nearpin") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn13 class="collapse-content">
                  {{ $t("text.common.longest_rank") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn13"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.longest_rank") }}</p>
              </b-collapse>
            </b-card>

            <b-card no-body class="rounded-0">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.tab-event-dfn14 class="collapse-content">
                  {{ $t("text.common.nearpin_rank") }}
                </div>
              </b-card-header>
              <b-collapse
                id="tab-event-dfn14"
                accordion="tab-event-accordion"
                role="tabpanel"
              >
                <p>{{ $t("text.dfn.nearpin_rank") }}</p>
              </b-collapse>
            </b-card>
          </div>
          <!-- // accordion -->
        </div>
        <!-- // TabName == event -->
      </div>

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-term-dfn')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TermDefinition",
  props: {
    getname: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      TabName: "scroe",
    };
  },
  created() {},
  mounted() {},
  methods: {
    getTermInfo(val) {
      this.TabName = val || "scroe";
      this.$refs["modal-term-dfn"].show();
    },
  },
  watch: {},
  mixins: [myMixin],
};
</script>