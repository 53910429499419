import i18n from "@/i18n";

export const myMixin = {
  data() {
    return {
      engMonth: {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      },
    };
  },
  methods: {
    tournament_status(status) {
      if (status === "1") {
        return i18n.t("text.common.ready");
      } else if (status === "2") {
        return i18n.t("text.common.playing");
      } else if (status === "3") {
        return i18n.t("text.common.completed");
      } else if (status === "4") {
        return i18n.t("text.common.cancel");
      }
    },
    tournament_type(type) {
      if (type === "1") {
        return i18n.t("text.common.status_public");
      } else if (type === "2") {
        return i18n.t("text.common.status_invitational");
      } else if (type === "3") {
        return i18n.t("text.common.status_private");
      }
    },
    event_status(status) {
      if (status === "0") {
        return i18n.t("text.common.ready");
      } else if (status === "1") {
        return i18n.t("text.common.playing");
      } else if (status === "2") {
        return i18n.t("text.common.completed");
      } else if (status === "3") {
        return i18n.t("text.common.cancel");
      }
    },
    change_status(status) {
      if (status == 0) {
        return i18n.t("text.common.ready");
      } else if (status == 1) {
        return i18n.t("text.common.playing");
      } else if (status == 2) {
        return i18n.t("text.common.completed");
      } else {
        return i18n.t("text.common.cancel");
      }
    },
    event_type(event) {
      if (event == 0) {
        return i18n.t("text.common.total");
      } else if (event == 1) {
        return i18n.t("text.common.holeinone");
      } else if (event == 2) {
        return i18n.t("text.common.albatross");
      } else if (event == 3) {
        return i18n.t("text.common.eagle_p5");
      } else if (event == 4) {
        return i18n.t("text.common.birdie_p5");
      } else if (event == 5) {
        return i18n.t("text.common.par_p5");
      } else if (event == 6) {
        return i18n.t("text.common.eagle_p4");
      } else if (event == 7) {
        return i18n.t("text.common.birdie_p4");
      } else if (event == 8) {
        return i18n.t("text.common.par_p4");
      } else if (event == 9) {
        return i18n.t("text.common.longest");
      } else if (event == 10) {
        return i18n.t("text.common.nearpin");
      } else if (event == 11) {
        return i18n.t("text.common.longest_rank");
      } else if (event == 12) {
        return i18n.t("text.common.nearpin_rank");
      }
    },
    changeDate(date) {
      let dateArray = date.split("-");
      let engDate =
        this.engMonth[dateArray[1]] + " " + dateArray[2] + ", " + dateArray[0];
      return engDate;
    },
    getDate(date) {
      if (date) {
        var date = new Date(date);
        var now_utc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );

        return new Date(now_utc).toISOString().split("T")[0];
      } else {
        return "";
      }
    },
    changeGender(gender) {
      if (gender === "M") {
        return i18n.t("text.common.male");
      } else if (gender === "F") {
        return i18n.t("text.common.female");
      } else {
        return "";
      }
    },
    play_type(mode) {
      if (mode === "1") {
        return i18n.t("text.common.stroke");
      } else if (mode === "2") {
        return i18n.t("text.common.match");
      } else if (mode === "3") {
        return i18n.t("text.common.skins");
      } else if (mode === "4") {
        return i18n.t("text.common.approach");
      } else if (mode === "5") {
        return i18n.t("text.common.driveway");
      } else if (mode === "6") {
        return i18n.t("text.common.par3_play");
      } else if (mode === "7") {
        return i18n.t("text.common.longest_contest");
      } else if (mode === "8") {
        return i18n.t("text.common.putting_contest");
      } else if (mode === "9") {
        return i18n.t("text.common.nearpin_contest");
      }
    },
    tournament_level(level) {
      if (level === "1") {
        return i18n.t("text.common.pro");
      } else if (level === "2") {
        return i18n.t("text.common.semi_pro");
      } else if (level === "3") {
        return i18n.t("text.common.advanced");
      } else if (level === "4") {
        return i18n.t("text.common.normal");
      } else if (level === "5") {
        return i18n.t("text.common.beginner");
      } else {
        return "-";
      }
    },
    event_level(level) {
      if (level === "1") {
        return i18n.t("text.common.pro");
      } else if (level === "2") {
        return i18n.t("text.common.semi_pro");
      } else if (level === "3") {
        return i18n.t("text.common.advanced");
      } else if (level === "4") {
        return i18n.t("text.common.normal");
      } else if (level === "5") {
        return i18n.t("text.common.beginner");
      } else {
        return "-";
      }
    },
    change_balltype(type) {
      if (type == 1) return i18n.t("text.common.straight");
      else if (type == 2) return i18n.t("text.common.hook");
      else if (type == 3) return i18n.t("text.common.slice");
      else if (type == 4) return i18n.t("text.common.pull_straight");
      else if (type == 5) return i18n.t("text.common.pull_hook");
      else if (type == 6) return i18n.t("text.common.pull_slice");
      else if (type == 7) return i18n.t("text.common.push_straight");
      else if (type == 8) return i18n.t("text.common.push_hook");
      else if (type == 9) return i18n.t("text.common.push_slice");
      else return "-";
    },
    change_wind(wind) {
      if (wind === "0") {
        this.wind = i18n.t("text.common.calm");
      } else if (wind === "1") {
        this.wind = i18n.t("text.common.light");
      } else if (wind === "2") {
        this.wind = i18n.t("text.common.moderate");
      } else if (wind === "3") {
        this.wind = i18n.t("text.common.strong");
      } else if (wind === "4") {
        this.wind = i18n.t("text.common.random");
      } else {
        this.wind = wind;
      }
    },
    change_male(teeboxman) {
      if (teeboxman === "1") {
        this.teemale =
          i18n.t("text.common.pro") + "(" + i18n.t("text.common.blue") + ")";
      } else if (teeboxman === "2") {
        this.teemale =
          i18n.t("text.common.regular") +
          "(" +
          i18n.t("text.common.white") +
          ")";
      } else if (teeboxman === "3") {
        this.teemale =
          i18n.t("text.common.lady") + "(" + i18n.t("text.common.red") + ")";
      } else if (teeboxman === "4") {
        this.teemale =
          i18n.t("text.common.senior") +
          "(" +
          i18n.t("text.common.yellow") +
          ")";
      } else {
        this.teemale = teeboxman;
      }
    },
    change_female(teeboxwoman) {
      if (teeboxwoman === "1") {
        this.teefemale =
          i18n.t("text.common.pro") + "(" + i18n.t("text.common.blue") + ")";
      } else if (teeboxwoman === "2") {
        this.teefemale =
          i18n.t("text.common.regular") +
          "(" +
          i18n.t("text.common.white") +
          ")";
      } else if (teeboxwoman === "3") {
        this.teefemale =
          i18n.t("text.common.lady") + "(" + i18n.t("text.common.red") + ")";
      } else if (teeboxwoman === "4") {
        this.teefemale =
          i18n.t("text.common.senior") +
          "(" +
          i18n.t("text.common.yellow") +
          ")";
      } else {
        this.teefemale = teeboxwoman;
      }
    },
  },
};
