<template>
  <footer>
    <div class="wrap d-flex flex-column">
      <div class="row row-cols-1 row-cols-lg-2 g-0">
        <div class="col footer-menu">
          <router-link :to="{ name: 'Terms' }">
            {{ $t("menu.common.terms_of_use") }}
          </router-link>
          <router-link :to="{ name: 'Privacy' }">
            {{ $t("menu.common.privacy_policy") }}
          </router-link>
          <a href="mailto:info@xgolf.com.au">
            {{ $t("menu.common.contact_us") }}
          </a>
        </div>

        <div class="col">
          <div
            class="row row-cols-1 row-cols-sm-2 justify-content-lg-end g-0 my-3 my-lg-0"
          >
            <div class="col">
              <ul
                class="sns d-flex flex-wrap justify-content-lg-end me-sm-4 mb-2 mb-sm-0"
              >
                <li class="mb-2 mb-sm-0">
                  <a
                    href="https://www.youtube.com/channel/UCrJAub0AHpYrOWA2Iv6ZndQ"
                    target="_blank"
                  >
                    <img src="/images/ico_social1.png" class="img-fluid" />
                  </a>
                </li>
                <li class="mb-2 mb-sm-0">
                  <a
                    href="https://www.facebook.com/Xgolfofficial-115944476859977"
                    target="_blank"
                  >
                    <img src="/images/ico_social3.png" class="img-fluid" />
                  </a>
                </li>
                <li class="mb-2 mb-sm-0">
                  <a
                    href="https://www.instagram.com/xgolfofficial/"
                    target="_blank"
                  >
                    <img src="/images/ico_social4.png" class="img-fluid" />
                  </a>
                </li>
                <li class="mb-2 mb-sm-0">
                  <a href="https://medium.com/xgolf" target="_blank">
                    <img src="/images/ico_social2.png" class="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="col">
              <div
                class="family-site"
                :class="{ active: fs }"
                v-click-outside="fs_close"
              >
                <p @click="fs_view">{{ $t("filter.common.family_site") }}</p>
                <div>
                  <a
                    href="http://www.thegolf.com"
                    target="_blank"
                    @click="fs_view"
                  >
                    {{ $t("filter.common.family_site_hq") }}
                  </a>
                  <a
                    href="https://www.xgolfofficial.com"
                    target="_blank"
                    @click="fs_view"
                  >
                    {{ $t("filter.common.family_site_franchise") }}
                  </a>
                  <a
                    href="https://playxgolf.com"
                    target="_blank"
                    @click="fs_view"
                  >
                    {{ $t("filter.common.family_site_america") }}
                  </a>
                  <!-- <a href="http://www.xgolf.com.au" target="_blank" @click="fs_view">
                    {{ $t("filter.common.family_site_au") }}
                  </a> -->
                  <a
                    href="http://www.xgolf.ae"
                    target="_blank"
                    @click="fs_view"
                  >
                    {{ $t("filter.common.family_site_uae") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row g-0 my-3 my-lg-4">
        <div class="copyright order-2 order-md-1 mt-3- mt-md-0"><!-- mt-3- 타임존 관련 class -->
          {{ $t("text.common.hq-address") }}<br>
          &copy; {{ $t("text.common.copyright") }}
          <!-- <img src="/img/logo_white.png" class="logo" @click="$router.push('/').catch(() => {})"> -->
        </div>
      </div>
    </div>

    <a
      href="#wrap"
      class="gotop"
      :class="{ on: last_scroll_position > 300, bottom: is_bottom }"
    >
      <i class="material-icons">expand_less</i>
    </a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      family: "",
      fs: false,
      is_bottom: false,
      last_scroll_position: 0,

      login: false,
    };
  },
  methods: {
    fs_view() {
      if (this.fs) this.fs = false;
      else this.fs = true;
    },
    fs_close() {
      this.fs = false;
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.last_scroll_position = currentScrollPosition;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.is_bottom = true;
      } else {
        this.is_bottom = false;
      }
    },
  },
  mounted() {
    if (this.$store.state.status === "success") {
      this.login = true;
    }
    window.addEventListener("scroll", this.onScroll);
  },
  watch: {
    "$store.state.status": function () {
      this.login = this.$store.state.status === "success" ? true : false;
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          // when target is not element or is not element childs
          if (!(el == event.target || el.contains(event.target))) {
            // call function
            vnode.context[binding.expression](event);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
};
</script>