import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    timezone: {
      timezone_name: "",
      timezone_offset: 0,
      state: "",
    },
    status: "ready", // ready, loading, error, success,
    searchData: {},
  },
  mutations: {
    authRequest(state) {
      state.status = "loading";
    },
    authSuccess(state) {
      state.status = "success";
      state.searchData = {};
    },
    authError(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "ready";
      state.searchData = {};
      state.timezone = {
        timezone_name: "",
        timezone_offset: 0,
        state: "",
      };
    },
    setSearchData(state, payload) {
      if (Object.keys(payload).length === 0) state.searchData = {};
      state.searchData = { ...state.searchData, ...payload };
    },
    setTimezone(state, payload) {
      console.log("payload", payload);
      state.timezone = { ...state.timezone, ...payload };
    },
  },
  actions: {
    login({ commit }, { token, user, pk, is_initialize }) {
      commit("authRequest");
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("user", user);
      sessionStorage.setItem("pk", pk);
      sessionStorage.setItem("is_initialize", is_initialize);
      commit("authSuccess");
    },
    logout({ commit }) {
      sessionStorage.removeItem("token");
      commit("logout");
    },
    check_login({ commit }) {
      commit("authSuccess");
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
